/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsOrganisationEntity from 'Models/Security/Acl/VisitorsOrganisationEntity';
import AdministratorOrganisationEntity from 'Models/Security/Acl/AdministratorOrganisationEntity';
import OrganisationAdminOrganisationEntity from 'Models/Security/Acl/OrganisationAdminOrganisationEntity';
import SiteUserOrganisationEntity from 'Models/Security/Acl/SiteUserOrganisationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	name: string;
	active: boolean;

	customFormVersions: Array<
		| Models.CustomFormVersionEntity
		| Models.ICustomFormVersionEntityAttributes
	>;
	organisationAdmins: Array<
		| Models.OrganisationAdminEntity
		| Models.IOrganisationAdminEntityAttributes
	>;
	sites: Array<
		| Models.SiteEntity
		| Models.ISiteEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model
	implements IOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOrganisationEntity(),
		new AdministratorOrganisationEntity(),
		new OrganisationAdminOrganisationEntity(),
		new SiteUserOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Active'] off begin
	@Validators.Required()
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Active',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public active: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Active'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Custom Form Version'] on begin
		name: 'Custom Form Versions',
		displayType: 'hidden',
		order: 30,
		referenceTypeFunc: () => Models.CustomFormVersionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customFormVersions',
			oppositeEntity: () => Models.CustomFormVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Custom Form Version'] end
	})
	public customFormVersions: Models.CustomFormVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation Admin'] off begin
		name: 'Organisation Admins',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.OrganisationAdminEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'organisationAdmins',
			oppositeEntity: () => Models.OrganisationAdminEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation Admin'] end
	})
	public organisationAdmins: Models.OrganisationAdminEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Site'] off begin
		name: 'Sites',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.SiteEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sites',
			oppositeEntity: () => Models.SiteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Site'] end
	})
	public sites: Models.SiteEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.active !== undefined) {
				this.active = attributes.active;
			}
			if (attributes.customFormVersions !== undefined && Array.isArray(attributes.customFormVersions)) {
				for (const model of attributes.customFormVersions) {
					if (model instanceof Models.CustomFormVersionEntity) {
						this.customFormVersions.push(model);
					} else {
						this.customFormVersions.push(new Models.CustomFormVersionEntity(model));
					}
				}
			}
			if (attributes.organisationAdmins !== undefined && Array.isArray(attributes.organisationAdmins)) {
				for (const model of attributes.organisationAdmins) {
					if (model instanceof Models.OrganisationAdminEntity) {
						this.organisationAdmins.push(model);
					} else {
						this.organisationAdmins.push(new Models.OrganisationAdminEntity(model));
					}
				}
			}
			if (attributes.sites !== undefined && Array.isArray(attributes.sites)) {
				for (const model of attributes.sites) {
					if (model instanceof Models.SiteEntity) {
						this.sites.push(model);
					} else {
						this.sites.push(new Models.SiteEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		customFormVersions {
			${Models.CustomFormVersionEntity.getAttributes().join('\n')}
		}
		organisationAdmins {
			${Models.OrganisationAdminEntity.getAttributes().join('\n')}
		}
		sites {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			customFormVersions: {},
			organisationAdmins: {},
			sites: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name || this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
