import React from 'react';
import { getAttributeComponent } from '../../../../Components/CRUD/Attributes/AttributeFactory';
import { CustomFormVersionEntity, OrganisationEntity } from '../../../../../Models/Entities';
import { EntityFormMode } from '../../../../Components/Helpers/Common';
import { observer } from 'mobx-react';
import { isRequired } from '../../../../../Util/EntityUtils';
import alertToast from '../../../../../Util/ToastifyUtils';
import { store } from '../../../../../Models/Store';
import { Form } from '../../../../Components/Form/Form';
import { isApolloError } from '@apollo/client';
import useAsync from '../../../../../Hooks/useAsync';
import Spinner from '../../../../Components/Spinner/Spinner';
import _ from 'lodash';
import { action, runInAction } from 'mobx';
import { Button, Colors, Display } from '../../../../Components/Button/Button';
import If from '../../../../Components/If/If';

interface IOrganisationEntityCRUDForm {
	organisationId?: string;
	formMode: EntityFormMode;
}

const OrganisationEntityCRUDForm = observer((props: IOrganisationEntityCRUDForm) => {
	const { organisationId, formMode } = props;
	const { type, error, data } = useAsync(() => {
		if (organisationId) {
			return OrganisationEntity.fetch<OrganisationEntity>(
				{
					id: organisationId,
				},
			);
		}

		return new Promise<OrganisationEntity[]>(resolve => {
			const newOrganisation = new OrganisationEntity();

			newOrganisation.customFormVersions = [new CustomFormVersionEntity()];

			resolve([newOrganisation]);
		});
	}, [organisationId]);

	if (type === 'loading') {
		return <Spinner />;
	}

	if (type === 'error') {
		return <div>{error.response.data}</div>;
	}

	if (data.length === 0) {
		return <div>Nothing!</div>;
	}

	const organisationModel = data[0];
	const customFormVersionModel = organisationModel.customFormVersions[0];

	const organisationCrudAttributes = organisationModel.getAttributeCRUDOptions();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const organisationNameAttribute = organisationCrudAttributes.find(x => x.attributeName === 'name')!;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeAttribute = organisationCrudAttributes.find(x => x.attributeName === 'active')!;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const sitesAttribute = organisationCrudAttributes.find(x => x.attributeName === 'sites')!;

	const customFormVersionCrudAttributes = customFormVersionModel.getAttributeCRUDOptions();
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const baseFormAttribute = customFormVersionCrudAttributes.find(x => x.attributeName === 'baseFormId')!;

	const organisationNameComponent = getAttributeComponent(
		organisationNameAttribute,
		organisationModel,
		organisationModel.getErrorsForAttribute(organisationNameAttribute.attributeName),
		formMode,
		isRequired(organisationModel, organisationNameAttribute.attributeName),
		() => {
			organisationModel.clearErrors();
		},
		() => {
			organisationModel.validate.bind(organisationModel)();
		},
	);

	const activeComponent = getAttributeComponent(
		activeAttribute,
		organisationModel,
		organisationModel.getErrorsForAttribute(activeAttribute.attributeName),
		formMode,
		isRequired(organisationModel, activeAttribute.attributeName),
		() => {
			organisationModel.clearErrors();
		},
		() => {
			organisationModel.validate.bind(organisationModel)();
		},
	);

	const sitesComponent = getAttributeComponent(
		sitesAttribute,
		organisationModel,
		organisationModel.getErrorsForAttribute(sitesAttribute.attributeName),
		formMode,
		isRequired(organisationModel, sitesAttribute.attributeName),
		() => {
			organisationModel.clearErrors();
		},
		() => {
			organisationModel.validate.bind(organisationModel)();
		},
	);

	const baseFormComponent = getAttributeComponent(
		baseFormAttribute,
		customFormVersionModel,
		customFormVersionModel.getErrorsForAttribute(baseFormAttribute.attributeName),
		formMode,
		isRequired(customFormVersionModel, baseFormAttribute.attributeName),
		() => {
			customFormVersionModel.clearErrors();
		},
		() => {
			customFormVersionModel.validate.bind(customFormVersionModel)();
		},
	);

	const onSubmit = action((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		customFormVersionModel.validate().then(() => {
			if (!customFormVersionModel.baseFormId) {
				alertToast('Base form is required!', 'error');
				return;
			}

			if (formMode === EntityFormMode.CREATE) {
				customFormVersionModel.formdata = 'CREATE_ORG_FORM_DATA_PLACEHOLDER';
			}

			if (organisationModel.customFormVersions.length === 0) {
				runInAction(() => {
					organisationModel.customFormVersions = [customFormVersionModel];
				});
			}

			organisationModel.validate().then(() => {
				organisationModel.saveFromCrud(formMode).then(() => {
					alertToast(`Successfully ${formMode} organisation`, 'success');
					store.routerHistory.goBack();
				}).catch((e: Error) => {
					if (isApolloError(e)) {
						if (e.graphQLErrors.length > 0) {
							alertToast(e.graphQLErrors.map((x: {message: string}) => x.message), 'error');
						} else if (
							e.networkError !== null
							&& 'result' in e.networkError
							&& e.networkError.result.errors
							&& e.networkError.result.errors.length > 0
						) {
							alertToast(
								e.networkError.result.errors.map(
									(x: {message: string}) => `${x.message.split('\n')[0].split(':')[1]}\n`,
								),
								'error',
							);
						} else {
							alertToast(e.message, 'error');
						}
					} else {
						alertToast(e.message, 'error');
					}
				});
			});
		});
	});

	return (
		<div className="crud-component">
			<section className="crud__create">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<h2>{_.capitalize(formMode.toString())} organisation</h2>
					<If condition={props.formMode !== EntityFormMode.CREATE}>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={
								() => store
									.routerHistory
									.push(`/admin/Organisation/${organisationModel.customFormVersions[0].id}/editForm`)
							}
						>
							Edit organisation form
						</Button>
					</If>
				</div>
				<Form
					submitButton={formMode !== EntityFormMode.VIEW}
					cancelButton
					onSubmit={onSubmit}
					onCancel={() => store.routerHistory.goBack()}
				>
					<div className="crud__form-container">
						{organisationNameComponent}
						{activeComponent}
						{sitesComponent}
						{baseFormComponent}
					</div>
				</Form>
			</section>
		</div>
	);
});

export default OrganisationEntityCRUDForm;
